import {makeStyles} from '@material-ui/core';

export default makeStyles((theme) => ({
    header: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 100%)',
        backdropFilter: 'blur(8px)',
        zIndex: '998',
    },

    headerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '24px',
        paddingBottom: '24px',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },

    heroDescription: {
        fontSize: '32px',
        fontWeight: '400',

        [theme.breakpoints.up('md')]: {
            fontSize: '40px',
        },
    },

    logo: {
        marginBottom: '24px',

        [theme.breakpoints.up('md')]: {
            marginBottom: '0',
        },
    },

    heroSection: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minHeight: '80vh',
        paddingTop: '120px',
    },

    displayTitle: {
        display: 'block',
        width: '100%',
        fontFamily: '"Staatliches", cursive',   
        textAlign: 'center',
    },

    firstLine: {
        display: 'block',
        fontSize: '8vw',
        letterSpacing: '8px',

        [theme.breakpoints.up('md')]: {
            fontSize: '4vw',
        },
    },

    secondLine: {
        display: 'block',
        fontSize: '16vw',        

        [theme.breakpoints.up('md')]: {
            fontSize: '8vw',            
        },
    },

    divider: {
        display: 'block',
        width: '100%',
        height: '1px',
        background: 'rgba(255,255,255,0.4)',
        margin: '80px auto',
    },

    canvas: {
        position: 'absolute',
        top: 'calc(100vh/2 - 300px)',
        left: 'calc(100vw/2 - 300px)',
        zIndex: -1,
    },

    rocket: {
        mixBlendMode: 'difference',
        position: 'fixed',
        zIndex: '999',        
        transition: 'transform 0.6s ease-in-out',
        pointerEvents: 'none'

        // transform: 'rotateZ(45deg)',

        // '&.up': {
        //     transform: 'rotateZ(45eg)',
        // },

        // '&.left': {
        //     transform: 'rotateZ(-90deg)',
        // },

        // '&.right': {
        //     transform: 'rotateZ(90deg)',
        // },

        // '&.down': {
        //     transform: 'rotateZ(225deg)',
        // },
    },

    card: {
        display: 'block',
        width: '100%',
        height: '100%',
        padding: '40px 32px 32px',
        border: '1px solid rgba(255,255,255,0.2)',
        background: 'black',
        transition: 'border 0.32s ease-in-out',

        '&:hover': {
            border: '1px solid rgba(255,255,255,0.4)',            
        }
    },

    service: {
        padding: '24px 0'
    }
}));
