import React, { useEffect } from 'react';
import logo from './../../logo.svg';
import {Box, Container, Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import usePageStyles from './App.style';
import './App.css';


function App() {
  const classes = usePageStyles();

  useEffect(() => {    
    const rocket = document.getElementById('rocket');    
  
    const canvas = document.getElementById('canvas1');
    const ctx = canvas.getContext('2d');

    var canvasElement = canvas.getBoundingClientRect();
    // var canvasX = e.clientX - canvasElement.left;
    // var canvasY = e.clientY - canvasElement.top;
    const lastPoint = {x: null, y: null}

    canvas.width = 600;
    canvas.height = 600;

    let particleArray = [];

    let adjustX = -10;
    let adjustY = -10;

    // handle mouse
    let mouse = {
      x: null,
      y: null,
      radius: 100
    };

    window.addEventListener('mousemove', function(event){
      mouse.x = event.clientX - canvasElement.left;
      mouse.y = event.clientY - canvasElement.top;

      // const leftOrRight = (
      //   event.clientX > lastPoint.x ? 'right'
      //   : event.clientX < lastPoint.x ? 'left'
      //   : 'none'
      // )
      // const upOrDown = (
      //   event.clientY > lastPoint.y ? 'down' : event.clientY < lastPoint.y ? 'up' : 'up'
      // )      
      // updateRocket(event.clientX, event.clientY, upOrDown);

      // lastPoint.x = event.clientX
      // lastPoint.y = event.clientY

      updateRocket(event.clientX, event.clientY)
    });

    function updateRocket(x, y, angle) {
      rocket.style.left = x - 50 + 'px';
      rocket.style.top = y - 50 + 'px';
      // if (rocket.classList.contains('up'))
      // {
      //   rocket.classList.remove('up');
      // }

      // if (rocket.classList.contains('down'))
      // {
      //   rocket.classList.remove('down');
      // }
      // rocket.classList.remove('up', 'down');
      // rocket.classList.add(angle);
    }

    ctx.fillStyle = 'rgb(255, 255, 255);';
    ctx.globalAlpha = 0.6;
    // ctx.font = '40px Verdana';
    // ctx.fillText('Rocket', 0, 40);
    ctx.beginPath();
    ctx.arc(20, 20, 10, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    const imageCoordinates = ctx.getImageData(0,0, 40, 40);

    class Particle {
      constructor(x, y) {
        this.x = x;
        this.y = y;
        this.size = 2;
        this.baseX = this.x;
        this.baseY = this.y;
        this.density = (Math.random() * 30) + 1;
      }

      draw() {
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      }

      update() {
        let dx = mouse.x - this.x;
        let dy = mouse.y - this.y;
        let distance = Math.sqrt(dx * dx + dy * dy);
        let forceDirectionX = dx / distance;
        let forceDirectionY = dy / distance;
        let maxDistance = mouse.radius;
        let force = (maxDistance - distance) / maxDistance;
        let directionX = forceDirectionX * force * this.density;
        let directionY = forceDirectionY * force * this.density;

        if (distance < mouse.radius){
          this.x -= directionX;
          this.y -= directionY;
          this.size = 4;
          
        } else {
          this.size = 2;
          if (this.x !== this.baseX){
            let dx = this.x - this.baseX;
            this.x -= dx/5;
          }
          if (this.y !== this.baseY){
            let dy = this.y - this.baseY;
            this.y -= dy/5;
          }
        }
      }
    };

    function init() {
      particleArray = [];
      for(let y = 0, y2 = imageCoordinates.height; y < y2; y++){
        for(let x = 0, x2 = imageCoordinates.width; x < x2; x++){
          if (imageCoordinates.data[(y * 4 * imageCoordinates.width) + (x * 4) + 3] > 128){
            let positionX = x + adjustX;
            let positionY = y + adjustY;
            particleArray.push(new Particle(positionX*30, positionY*30));            
          }
        }
      }
    };

    init();

    // console.log(particleArray);

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < particleArray.length; i++){
        particleArray[i].draw();
        particleArray[i].update();
      }
      requestAnimationFrame(animate);
    }

    animate();
  });

  return (
    <Box className="main">
      {/* <svg id="rocket" className={classes.rocket} width="56" height="64" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 32L14 0L28 32H0Z" fill="white"/>
      </svg> */}
      <svg id="rocket" width="100" height="100" className={classes.rocket} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" fill="url(#skyGradient)"/>
        <defs>
          <linearGradient id="skyGradient" x1="100%" y1="100%">
              <stop offset="0%" stop-color="lightblue" stop-opacity="1">
                  <animate attributeName="stop-color" values="#EA4FA0; #F3A651; #964BF3; #7DFBFD; #B8FB84; #EA4FA0;" dur="8s" repeatCount="indefinite" />
              </stop>
              <stop offset="100%" stop-color="lightblue" stop-opacity="1">
                  <animate attributeName="stop-color" values="#7DFBFD; #B8FB84; #EA4FA0; #EA4FA0; #F3A651; #964BF3; " dur="8s" repeatCount="indefinite" />                  
              </stop>
          </linearGradient>
        </defs>
      </svg>
      <Box component="canvas" id="canvas1" className={classes.canvas}/>
      <Box component="header" className={classes.header}>
        <Container className={classes.headerContent}>
          <img src={logo} className={classes.logo} alt="logo" />
          <Typography>contact@amazingrocket.io</Typography>
        </Container>
      </Box>
      <Box component="section">
        <Container className={classes.heroSection}>
          <Typography variant="h1" className={classes.displayTitle}>
            <Box component="span" className={classes.firstLine}>software development</Box>
            <Box component="span" className={classes.secondLine}>like it’s rocket science.</Box>
          </Typography>          
        </Container>
      </Box>      
      <Box component="section">
        <Container>
          <Typography variant="h3" className={classes.heroDescription}>We love working with the latest technologies to solve problems that product companies are faced with. It might not be rocket science, but we’re tackling the issues of today with a holistic mindset having our gaze set upon the future.        </Typography>
          <Box component="span" className={classes.divider} />
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box className={classes.card}>
                <Typography className={classes.number}>01</Typography>
                <Typography variant="h4" className={classes.service}>User eXperience<br />Design</Typography>
                <Typography variant="subtitle1">Best in class user experience design</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className={classes.card}>
                <Typography className={classes.number}>02</Typography>
                <Typography variant="h4" className={classes.service}>Software<br />Development</Typography>
                <Typography variant="subtitle1">Best in class user software development</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className={classes.card}>
                <Typography className={classes.number}>03</Typography>
                <Typography variant="h4" className={classes.service}>Machine<br />Learning</Typography>
                <Typography variant="subtitle1">Should we say more?</Typography>
              </Box>
            </Grid>       
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default App;
